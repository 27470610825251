.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: white;
  text-decoration: none;
}

.dropdowncss {
  position: absolute;
  box-shadow: 0px 5px 10px gray;
  font-size: 0.875rem;
  min-width: 10rem;
  padding: 0.5rem 0rem;
  list-style: none;
  background-color: #003824;
  border-radius: 0.1rem;
  z-index: 999;
  display: none;
}

@media (max-width: 600px) {
  .dropdowncss {
    box-shadow: none;
    background: none;
    line-height: 1.6;
    margin-top: 2%;
  }
  .line {
    margin-left: -16%;
  }
  .dropdowncss.dropdowncss-submenu {
    width: 70%;
    margin-left: -4%;
  }
}

.dropdowncss.show {
  display: block;
}

.dropdowncss.dropdowncss-submenu {
  position: absolute;
  box-shadow: none;
  right: -90%;
  top: -4px;
}
